import {
  Box,
  Email,
  Facebook,
  HokenLogo,
  Instagram,
  Link,
  Linkedin,
  StripeBadge,
  Tiktok,
  getCurrentYear,
} from "@hoken/core";

import { FooterWrapper } from "./footer-desktop-wrapper.styles";

import { Icons } from "@hoken/design-system";

export const FooterDesktopWrapper = () => {
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FooterWrapper className='text-white'>
      <div className='footer--company-info'>
        <div className='footer--row footer--company-branding'>
          <div className='footer--company-logo'>
            <HokenLogo onClick={goToTop} />
          </div>
        </div>
        <div className='footer--row footer--company-links'>
          <div>
            <ul>
              <li>Company</li>
              <li>
                <Link href='/about-hoken' legacyBehavior>
                  <a>About Hoken</a>
                </Link>
              </li>
              <li>
                <a href='https://www.gohoken.com/blog'>Blog</a>
              </li>
              <li>
                <Link href='/terms-of-use' legacyBehavior>
                  <a>Terms of Use</a>
                </Link>
              </li>
              <li>
                <Link href='/privacy-statement' legacyBehavior>
                  <a>Privacy Policy</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Info</li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us/articles/25358342725275-Trust-Safety'
                >
                  Trust & Safety
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://buy.gohoken.com/marketplace/'
                >
                  Sell
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  FAQ
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://hoken.zendesk.com/hc/en-us'
                >
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer--contact-and-rights'>
        <Box
          display='flex'
          css={{ flexDirection: "column", justifyContent: "space-between" }}
        >
          <div className='footer--company-slogan'>Perfect stays for epic events.</div>
          <div>
            <a href='mailto:support@gohoken.com' className='icon'>
              <Email />
            </a>
            <a
              href='https://twitter.com/gohoken'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Icons.XLogo />
            </a>
            <a
              href='https://www.instagram.com/gohoken/'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Instagram />
            </a>
            <a
              href='https://www.tiktok.com/@gohoken'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Tiktok />
            </a>
            <a
              href='https://www.facebook.com/profile.php?id=100086708942818'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Facebook
                css={{
                  marginTop: "-3px",
                }}
              />
            </a>
            <a
              href='https://www.linkedin.com/company/gohoken/'
              className='icon'
              target='_blank'
              rel='noreferrer'
            >
              <Linkedin
                css={{
                  marginTop: "-2px",
                }}
              />
            </a>
          </div>
        </Box>
        <Box css={{ textAlign: "right" }}>
          <p>&copy; Hoken {getCurrentYear()}</p>
          <Box css={{ paddingBottom: "1rem" }} />
          <StripeBadge linked={true} />
        </Box>
      </div>
    </FooterWrapper>
  );
};
