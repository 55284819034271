import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

/* istanbul ignore next */
export const EmailSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 19 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M17.1709 0.0629883C18.085 0.0629883 18.8584 0.836426 18.8584 1.75049C18.8584 2.31299 18.5771 2.80518 18.1553 3.12158L10.5264 8.85205C10.1045 9.16846 9.57715 9.16846 9.15527 8.85205L1.52637 3.12158C1.10449 2.80518 0.858398 2.31299 0.858398 1.75049C0.858398 0.836426 1.59668 0.0629883 2.5459 0.0629883H17.1709ZM8.4873 9.76611C9.2959 10.3638 10.3857 10.3638 11.1943 9.76611L18.8584 4.00049V11.313C18.8584 12.5786 17.8389 13.563 16.6084 13.563H3.1084C1.84277 13.563 0.858398 12.5786 0.858398 11.313V4.00049L8.4873 9.76611Z'
      fill='white'
    />
  </svg>
);

export const Email = styled(EmailSVG, {
  height: "1.25rem",
  width: "1.25rem",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
});
