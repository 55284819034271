import { styled } from "@hoken/core/styles/stitches.config";

export const FooterWrapper = styled("footer", {
  backgroundColor: "$black",
  overflow: "hidden",
  backgroundSize: "cover",
  width: "100%",
  position: "relative",
  minHeight: "126px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  padding: "2.68rem 4rem",
  margin: "0 auto",
  marginTop: "0",
  zIndex: "2",

  ".footer--row": {
    width: "50%",
  },
  ".footer--hoken-logo": {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    svg: {
      margin: "$0",
    },
  },
  ".footer--contact-us": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: ".85rem",
    fontFamily: "libre franklin",
    marginTop: "1rem",
    cursor: "pointer",
    svg: {
      width: "24px",
      height: "24px",
      "&:nth-child(2)": {
        filter: "invert(1)",
      },
    },
  },
  ".footer--company-logo": {
    svg: {
      marginLeft: "$0",
    },
  },
  ".footer--company-info": {
    display: "flex",
    width: "100%",
  },
  ".footer--company-branding": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  ".footer--company-links": {
    display: "flex",
    justifyContent: "end",
    gap: "2rem",
    fontFamily: "$franklin",
    ul: {
      minWidth: "162px",
    },
    li: {
      marginBottom: "1rem",
      fontSize: "$hoken-core-font-15",
      "&:nth-child(1)": {
        fontWeight: "$bold",
      },
    },
    span: {
      color: "$green",
    },
  },
  ".footer--company-slogan": {
    fontSize: "$hoken-core-font-24",
    fontFamily: "$helvetica",
  },
  ".footer--contact-and-rights": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "1.125rem",
    p: {
      fontSize: "$hoken-core-font-15",
    },
    ".icon svg": {
      height: "24px",
      width: "24px",
      marginRight: "1rem",
      marginLeft: "$0",
      display: "initial",
    },
    path: {
      fill: "$white",
    },
  },
});
