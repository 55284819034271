import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

const InstagramSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M11 5.29834C8 5.29834 5.60938 7.73584 5.60938 10.689C5.60938 13.689 8 16.0796 11 16.0796C13.9531 16.0796 16.3906 13.689 16.3906 10.689C16.3906 7.73584 13.9531 5.29834 11 5.29834ZM11 14.2046C9.07812 14.2046 7.48438 12.6577 7.48438 10.689C7.48438 8.76709 9.03125 7.22021 11 7.22021C12.9219 7.22021 14.4688 8.76709 14.4688 10.689C14.4688 12.6577 12.9219 14.2046 11 14.2046ZM17.8438 5.11084C17.8438 5.81396 17.2812 6.37646 16.5781 6.37646C15.875 6.37646 15.3125 5.81396 15.3125 5.11084C15.3125 4.40771 15.875 3.84521 16.5781 3.84521C17.2812 3.84521 17.8438 4.40771 17.8438 5.11084ZM21.4062 6.37646C21.3125 4.68896 20.9375 3.18896 19.7188 1.97021C18.5 0.751465 17 0.376465 15.3125 0.282715C13.5781 0.188965 8.375 0.188965 6.64062 0.282715C4.95312 0.376465 3.5 0.751465 2.23438 1.97021C1.01562 3.18896 0.640625 4.68896 0.546875 6.37646C0.453125 8.11084 0.453125 13.314 0.546875 15.0483C0.640625 16.7358 1.01562 18.189 2.23438 19.4546C3.5 20.6733 4.95312 21.0483 6.64062 21.1421C8.375 21.2358 13.5781 21.2358 15.3125 21.1421C17 21.0483 18.5 20.6733 19.7188 19.4546C20.9375 18.189 21.3125 16.7358 21.4062 15.0483C21.5 13.314 21.5 8.11084 21.4062 6.37646ZM19.1562 16.8765C18.8281 17.814 18.0781 18.5171 17.1875 18.8921C15.7812 19.4546 12.5 19.314 11 19.314C9.45312 19.314 6.17188 19.4546 4.8125 18.8921C3.875 18.5171 3.17188 17.814 2.79688 16.8765C2.23438 15.5171 2.375 12.2358 2.375 10.689C2.375 9.18896 2.23438 5.90771 2.79688 4.50146C3.17188 3.61084 3.875 2.90771 4.8125 2.53271C6.17188 1.97021 9.45312 2.11084 11 2.11084C12.5 2.11084 15.7812 1.97021 17.1875 2.53271C18.0781 2.86084 18.7812 3.61084 19.1562 4.50146C19.7188 5.90771 19.5781 9.18896 19.5781 10.689C19.5781 12.2358 19.7188 15.5171 19.1562 16.8765Z'
      fill='black'
    />
  </svg>
);

export const Instagram = styled(InstagramSVG, {
  height: "$hoken-core-icon-height",
  width: "$hoken-core-icon-width",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
  verticalAlign: "top",
});
