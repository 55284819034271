import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

const LinkedinSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M18.75 4.375H5.215c-.598 0-1.09.527-1.09 1.16V19c0 .633.492 1.125 1.09 1.125H18.75c.598 0 1.125-.492 1.125-1.125V5.535c0-.633-.527-1.16-1.125-1.16Zm-9.879 13.5h-2.32v-7.488h2.32v7.488Zm-1.16-8.543c-.774 0-1.371-.598-1.371-1.336 0-.738.598-1.371 1.37-1.371.74 0 1.337.633 1.337 1.371S8.449 9.332 7.71 9.332Zm9.914 8.543H15.27v-3.656c0-.844 0-1.969-1.196-1.969-1.23 0-1.406.95-1.406 1.934v3.691h-2.32v-7.488h2.214v1.02h.036c.316-.598 1.09-1.231 2.214-1.231 2.356 0 2.813 1.582 2.813 3.586v4.113Z'
      fill='black'
    />
  </svg>
);

export const Linkedin = styled(LinkedinSVG, {
  height: "$hoken-core-icon-height",
  width: "$hoken-core-icon-width",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
  verticalAlign: "top",
});
