import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

const FacebookSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 22 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M20.719 12.25A8.717 8.717 0 0 0 12 3.531a8.717 8.717 0 0 0-8.719 8.719c0 4.36 3.164 7.98 7.348 8.613v-6.082H8.414V12.25h2.215v-1.898c0-2.18 1.3-3.41 3.27-3.41.984 0 1.968.175 1.968.175v2.145h-1.09c-1.09 0-1.441.668-1.441 1.37v1.618h2.426l-.387 2.531h-2.04v6.082c4.184-.633 7.384-4.254 7.384-8.613Z'
      fill='black'
    />
  </svg>
);

export const Facebook = styled(FacebookSVG, {
  height: "$hoken-core-icon-height",
  width: "$hoken-core-icon-width",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
  verticalAlign: "top",
});
