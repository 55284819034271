import { styled } from "@hoken/core/styles/stitches.config";

import { SVGIconProps } from "../icons.interfaces";

const TiktokSVG = ({ title, titleId, ...props }: SVGIconProps) => (
  <svg
    viewBox='0 0 20 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d='M19.875 10.633c-1.582.035-3.059-.457-4.324-1.371v6.293c0 4.394-4.781 7.136-8.578 4.957-3.797-2.215-3.797-7.7 0-9.914a5.74 5.74 0 0 1 3.656-.703v3.164c-1.934-.633-3.797 1.09-3.375 3.058.457 1.969 2.848 2.742 4.36 1.371a2.66 2.66 0 0 0 .843-1.933V3.25h3.094c0 .281 0 .527.07.809.211 1.16.879 2.18 1.899 2.812.668.457 1.511.703 2.355.703v3.059Z'
      fill='black'
    />
  </svg>
);

export const Tiktok = styled(TiktokSVG, {
  height: "$hoken-core-icon-height",
  width: "$hoken-core-icon-width",
  margin: "$hoken-core-space-none $hoken-core-space-small",
  display: "initial",
  verticalAlign: "top",
});
